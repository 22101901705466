import React, { useEffect } from 'react';
import './css/tarifs.css';

function Tarifs() {

  useEffect(() => {
    document.title = "Tarifs";
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="Tarifs">
      <h1>Tarifs</h1>
      <div className="Tarifs-container">
        <img src="/images/itforalltable2025.png" alt="tarifs" className='Tarifs-table' />
      </div>
      <p className='Offer'>OFFRE DSI à temps Partagé 850 €/jours</p>
    </div>
  );
}

export default Tarifs;